.modal {
  display: flex;
  align-items: center;
  justify-content: end;
  font-family: 'Open Sans';
}

.modal .modal-header {
  background-color: #4e8eed;
  color: #ffffff;
}

.modal .modal-dialog {
  width: 30%;
}

.modal .modal-title {
  font-size: 14px;
  letter-spacing: 1px;
  font-weight: 600;
}

.modal .modal-label {
  font-weight: 600;
  margin: 3px 0px 3px 0px;
}

.modal .modal-content {
  box-shadow: none;
  border-radius: 0px;
  min-width: 420px;
  background: #f0f0f0;
}

.modal input[type='text'],
.modal select,
.modal textarea {
  font-size: 12px;
  padding: 6px;
  width: 180px;
}

.modal .modal-footer {
  border: 0px;
}

.modal .bootbox-close-button {
  color: #ffffff;
  opacity: 1;
}

input.error,
textarea.error {
  border: 1px solid #ff0000ba;
}

.skipped-dates {
  padding: 3px 8px 3px 8px;
  background-color: #f3dbdb;
  font-size: 12px;
  overflow-y: auto;
  max-height: 57px;
}

.skipped-dates img {
  margin-right: 7px;
}
