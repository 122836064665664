#calendar {
  margin: 20px;
  margin-bottom: 5%;
}

.fc-header-toolbar img {
  margin-top: 50%;
}

.fc-header-toolbar .active-technicians-div {
  margin-top: 0.5%;
  border: 1px solid #dddddd;
  padding: 5px;
}

.fc-header-toolbar .personnel-name-div {
  margin-top: 2%;
  padding: 5px;
  max-width: 300px;
}

.fc-head .dow {
  font-family: 'Open Sans';
  font-size: 1em;
}

.fc-head .ddmm {
  font-family: 'Open Sans';
  font-size: 2em;
}

.fc-head .ddmm-past {
  opacity: 0.5;
}

.fc-head .ddmm-current {
  color: #4e8eed;
}

.fc-time-grid .fc-slats td {
  font-size: 12px;
  border: unset;
}

.fc-time-grid .fc-slats span {
  margin-right: 10%;
}

.fc-toolbar .fc-left {
  font-family: 'Open Sans';
  margin-right: 20px;
  min-width: 23%;
}

.fc-toolbar .fc-center {
  display: inline;
}

.fc-toolbar .fc-prev-button,
.fc-toolbar .fc-next-button {
  background-color: #dddddd;
  background-image: none;
  width: 3em;
  height: 3em;
  border: none;
  border-radius: 1.5em;
}

.fc-toolbar .fc-prev-button {
  margin-right: 1em;
}

.fc-toolbar .fc-today-button {
  background-image: linear-gradient(#4e8eed, #4e8eed);
  background-color: #4e8eed;
  text-shadow: none;
  box-shadow: none;
  height: 3em;
  width: 6em;
  border-radius: 1px;
  text-transform: uppercase;
  color: #ffffff;
}

.fc .fc-time {
  vertical-align: top;
  width: 6%;
}

.fc-view-container {
  border: 1px solid #ddd;
}

.fc-view-container .fc-today {
  background-color: #f6f6f6;
}

.fc-view-container .fc-agendaWeek-view tr {
  height: 6.5em;
}

.fc-view-container .fc-agendaWeek-view td {
  border: none;
}

.fc-view-container .fc-end {
  border-radius: 0px;
  margin-right: -1px;
  margin-bottom: -1px;
}

.fc-event-container .fc-time,
.fc-event-container a > div {
  padding-top: 10%;
  text-align: center;
  font-size: 3em;
  width: 100%;
}

.fc-head .fc-day-header {
  text-transform: uppercase;
  padding-top: 35px;
}

.fc-body .fc-time-grid .fc-event-container {
  margin: unset;
}

a.edit {
  text-decoration: none;
  color: black;
  opacity: 0.5;
}

a.remove-link {
  font-size: 0.01em;
  color: red;
}

.fc-event-container .add-slot:not(.edit) {
  color: #1268e6;
}

.fc-now-indicator.fc-now-indicator-arrow {
  display: none;
}

.fc-event-container .past-event,
.fc-event-container .past-event.add-slot {
  color: #cccccc;
}

.fc-event-container .past-event.fc-event,
.fc-event-container .past-event .fc-event-dot {
  background: #a7a7a7;
  border-color: #848484;
}

.fc-event-container a > div.add-slot {
  margin-top: 10%;
  font-size: 14px;
}

.fc-event-container .fc-title .add-slot {
  margin-top: 10%;
}

.technician-number {
  background-color: rgb(232, 232, 233);
  padding: 0px 10px 0px 10px;
}

.fc-event-container .today-event {
  background-color: #f6f6f6 !important;
}

.empty-event {
  background-color: #f6f6f6 !important;
}

.fc-scroller {
  overflow-y: hidden !important;
}
