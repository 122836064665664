* {
  box-sizing: border-box;
}

*:before {
  box-sizing: border-box;
}

*:after {
  box-sizing: border-box;
}

html {
  height: 100%;
}

body {
  padding: 0;
  font-family: 'Lucida Grande', Helvetica, Arial, Verdana, sans-serif;
  font-size: 14px;
  min-width: 1000px;
  width: auto;
  height: 100%;
}

.container {
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 1em;
}

.form-control {
  display: unset;
}

input,
.additional_settings,
.help-div,
.fc-title > div {
  cursor: pointer;
}

.btn:active:focus,
.btn:focus,
button:focus {
  outline: none;
}

.select2-container .select2-selection--single {
  outline: none;
  border-radius: 0px;
  border: 1px solid #dddddd;
}

.calender-view .select2-container,
.settings-container .select2-container,
.audit-history-view .select2-container {
  width: auto !important;
}

.footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: #dddddd;
  color: #383f47;
  height: 40px;
  z-index: 10;
  padding-top: 10px;
  font-size: 12px;
}

.footer .help-div {
  float: left;
  margin-left: 15px;
}

.footer .mara-terms {
  float: right;
  margin-right: 15px;
}

.footer .help-text {
  font-weight: 600;
}

.footer .powered-by-locus > span {
  margin-right: 10px;
  vertical-align: middle;
}

.float-right {
  text-align: right;
}

.m-0 {
  margin: 0 !important;
}

.p-0 {
  padding: 0 !important;
}

.center-y {
  display: flex;
  align-items: center;
}

.center-x {
  display: flex;
  justify-content: center;
}

.center-x-y {
  display: flex;
  align-items: center;
  justify-content: center;
}

.mr-1 {
  margin-right: 1rem;
}

.mr-2 {
  margin-right: 2rem;
}

.font-weight-600 {
  font-weight: 600;
}

.grey-900 {
  color: #111318;
}

.grey-600 {
  color: #67718e;
}

.grey-300 {
  color: #d0d3dd;
}

.disabled {
  opacity: 0.5;
  pointer-events: none;
}

.cursor-pointer {
  cursor: pointer;
}

.font-12 {
  font-size: 1.2rem;
}

.error {
  color: #dc2518;
}

.align-right {
  display: flex;
  justify-content: flex-end;
}
