.create-ticket {
  overflow-y: scroll;
  max-height: 400px;
}

.modal .create-ticket input[type='text'],
.modal .create-ticket textarea {
  width: 100%;
}

.create-ticket input[type='file'] {
  display: none;
}

.create-ticket .custom-file-label {
  margin-left: 15px;
  margin-right: 15px;
}

.create-ticket .file-upload {
  border: 1px solid #ccc;
  padding: 6px 12px;
  cursor: pointer;
  background-color: white;
  width: 100%;
  text-align: center;
}

.create-ticket .attachment {
  margin: 6px 0px 6px 0px;
}
