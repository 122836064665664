:-webkit-scrollbar {
  border-radius: 30px;
  width: 6px;
  height: 6px;
}
:-webkit-scrollbar-thumb {
  background: #d0d3dd;
  border-radius: 30px;
}
:-webkit-scrollbar-track {
  border-radius: 30px;
}

.dataTables_wrapper {
  height: calc(100vh - 238px);
  width: 100%;
  font-size: 12px;
  word-break: break-all;
  border: 1px solid #eeeeee;
}

.dataTables_wrapper.no-footer .dataTables_scrollBody {
  border: none;
}

#dataTable {
  min-height: 38rem;
}

.dataTables_wrapper thead {
  color: #67718e;
  text-align: left;
  background-color: #f3f4f6;
  font-weight: 600;
}

table.dataTable thead th {
  border-bottom: none;
  padding: 1rem 1.2rem;
  border-right: 1px solid #eeeeee;
}

table.dataTable tbody td {
  padding: 1rem 1.2rem;
  border-right: 1px solid #eeeeee;
  border-bottom: 1px solid #eeeeee;
}

table.dataTable.no-footer {
  border-bottom: none;
}

table.dataTable td.dataTables_empty {
  height: calc(100vh - 275px);
  line-height: calc(100vh - 275px);
  padding: 0;
  font-size: 1.6rem;
}

/*Pagination BAR CSS starts here*/

.pagination-bar {
  position: fixed;
  height: 64px;
  width: 83.33333333%;
  bottom: 40px;
  background-color: #fff;
  box-shadow: 0px 0px 4px rgba(9, 10, 12, 0.16);
  font-size: 11px;
  display: flex;
  align-items: center;
}

.pagination-bar .row-wrapper {
  width: 100%;
  padding: 0 3rem;
}

.pagination-bar .btn {
  background-color: #fff;
  font-size: 11px;
  border: 1px solid #e7e9ee;
  padding: 3px 6px;
  display: flex;
}

.pagination-bar .dropdown-menu {
  min-width: 44px;
  font-size: 11px;
}

.pagination-bar .dropdown-menu > li > a {
  padding: 3px 8px;
}

.pagination-bar .arrow {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTYgMTMuMzMzM0w2IDIuNjY2NjZMMTEuMzMzMyA3Ljk5OTk5TDYgMTMuMzMzM1oiIGZpbGw9IiM2NzcxOEUiLz4KPC9zdmc+Cg==);
  height: 1.6rem;
  width: 1.6rem;
}

.pagination-bar .arrow.left {
  transform: rotate(180deg);
}

.pagination-bar .arrow.down {
  transform: rotate(90deg);
  opacity: 0.5;
}

.pagination-bar .arrow.up {
  transform: rotate(270deg);
  opacity: 0.5;
}

.pagination-bar .pageNumberButton {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #e7e9ee;
  border-radius: 50%;
  color: #111318;
  width: 2.4rem;
  height: 2.4rem;
}

.pagination-bar .pageNumberButton.selected {
  background-color: #0072f5;
  color: #fff;
}

.pagination-bar .gotoPageButton {
  cursor: pointer;
  width: 2.4rem;
  height: 2.4rem;
  background-color: #e7e9ee;
  border-radius: 50%;
}

.pagination-bar .gotoPageButtonImg {
  width: 1.6rem;
  height: 1.6rem;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgdmlld0JveD0iMCAwIDI0IDI0Ij4KICAgIDxnIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPHBhdGggZmlsbD0iIzY3NzE4RSIgZD0iTTIwIDExSDcuOGw1LjYtNS42TDEyIDRsLTggOCA4IDggMS40LTEuNEw3LjggMTNIMjB6Ii8+CiAgICA8L2c+Cjwvc3ZnPgo=);
  transform: rotate(180deg);
  background-repeat: no-repeat;
}

.pagination-bar input {
  width: 6.4rem;
  height: 2.4rem;
  font-size: 1.1rem;
}

.pagination-bar .gotoPage {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.pagination-bar li {
  text-align: center;
  cursor: pointer;
  line-height: 2rem;
}

.pagination-bar li:hover {
  background-color: #e7e9ee;
}

.pagination-bar li.selected {
  background-color: #e7e9ee;
}
