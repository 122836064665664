.settings-container {
  margin: 0px;
}

.navigation-bar {
  background-color: #fafafa;
  height: 4em;
  border: 1px solid #ddd;
  border-radius: 0px;
}

.settings-view a,
.settings-view a:hover {
  color: #383f47;
  text-decoration: none;
  cursor: default;
}

.settings-header {
  position: absolute;
  left: 4em;
  top: 0.75em;
  color: #383f47;
}

.label {
  margin-top: 5%;
}

.editable-input {
  font-size: 5em;
}

#change-technicians {
  width: 30px;
}

#shift.editable,
#technician-count.editable,
#min-technician-count.editable,
#slot-options.editable,
#travel-time.editable {
  border-bottom: dashed 1px #0088cc;
  cursor: pointer;
  font-style: inherit;
}

.settings-view .additional-options {
  margin-right: 1em;
  float: right;
  margin-top: 12.5px;
}

.settings-view .view-audit-history {
  display: flex;
  color: #3392ff;
  font-size: 1.2rem;
  cursor: pointer;
}

.settings-view .view-audit-history .blue-right-arrow {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgdmlld0JveD0iMCAwIDI0IDI0Ij4KICAgIDxnIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPHBhdGggZmlsbD0iIzMzOTJGRiIgZD0iTTIwIDExSDcuOGw1LjYtNS42TDEyIDRsLTggOCA4IDggMS40LTEuNEw3LjggMTNIMjB6Ii8+CiAgICA8L2c+Cjwvc3ZnPgo=);
  transform: rotate(180deg);
  width: 1.6rem;
  height: 1.6rem;
  margin-left: 0.5rem;
}
