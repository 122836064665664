.navbar {
  background: #151d28;
  border-radius: 0px;
}

input[type='text'],
input[type='password'],
input[type='number'],
textarea {
  border: 1px solid #dddddd;
  padding: 6px;
  border-radius: 0px;
}

input[type='text']:focus,
input[type='password']:focus,
input[type='number']:focus,
textarea:focus {
  outline: 0;
  border-color: #4e8eed;
  -webkit-box-shadow: 0 0 0.4rem 0 rgba(78, 142, 237, 0.5);
  box-shadow: 0 0 0.4rem 0 rgba(78, 142, 237, 0.5);
}

.input-label {
  font-size: 14px;
  margin: unset;
  font-weight: 500;
}

.btn,
.btn:focus,
.btn:active,
.btn:hover {
  background-color: #4e8eed;
  border-color: #4e8eed;
  border-radius: 1px;
}
